export default class FilterSidebar {
  constructor(selector) {
    this.root = document.querySelector(selector);

    if (this.root) {
      const close = this.root.querySelector('.filters-sidebar__close');
      close.onclick = () => this.close();

      this.filterMore = document.querySelector('.js-filter-more');
      this.filterMore.onclick = () => this.open();

      const boxes = this.root.querySelectorAll('.filter-box__btn');
      boxes.forEach((el) => {
        el.onclick = this._onCollapseClick;
      });

      this.onEscKeyDown = this._onEscKeyDown.bind(this);
      this.onOverlayClick = this._onOverlayClick.bind(this);
    }
  }

  open() {
    this.root.setAttribute('aria-hidden', 'false');
    document.body.classList.add('is-hidden');
    this.root.addEventListener('click', this.onOverlayClick, false);
    document.addEventListener('keydown', this.onEscKeyDown, false);
    this.root.classList.add('is-open');
  }

  close() {
    if (this.root.getAttribute('aria-hidden') !== 'true') {
      const toggleClassHandler = () => {
        document.body.classList.remove('is-hidden');
        document.removeEventListener(
          'keydown',
          this._onEscKeyDown,
          false
        );
        this.root.classList.remove('is-open');
        this.filterMore.classList.remove('is-open');
        this.root.removeEventListener(
          'click',
          this.onOverlayClick,
          false
        );
        this.root.removeEventListener(
          'animationend',
          toggleClassHandler,
          false
        );
      };
      this.root.addEventListener(
        'animationend',
        toggleClassHandler,
        false
      );
    }
    this.root.setAttribute('aria-hidden', 'true');
  }

  _onOverlayClick(e) {
    if (e.target.classList.contains('filters-sidebar__overlay')) {
      this.close();
    }
  }

  _onEscKeyDown(e) {
    if (e.keyCode === 27) {
      this.close();
    }
  }

  _onCollapseClick(e) {
    const button = e.target.closest('.filter-box__btn');
    if (button == null) {
      return;
    }
    const isClose = button.getAttribute('aria-expanded') === 'false';
    button.closest('.filter-box').classList.toggle('is-close', !isClose);
    button.setAttribute('aria-expanded', isClose);
  }
}
