import Swiper from 'swiper/js/swiper.js';

export default class Promo {
  constructor(selector) {
    this.selector = selector;
    this.slider = document.querySelector(selector);

    if (this.slider) {
      new Swiper(this.selector, {
        // autoplay: {
        //   delay: 5000,
        // },
        loop: false,
        spaceBetween: 16,
        slidesPerView: 1,
        navigation: {
          nextEl: '.js-promo-next',
          prevEl: '.js-promo-prev',
        },
        pagination: {
          el: '.js-promo-pagination',
          type: 'bullets',
          clickable: true
        },
        breakpoints: {
          320: {
            slidesPerView: 1
          },
          700: {
            slidesPerView: 2
          },
          2048: {
            slidesPerView: 3
          },
          3072: {
            slidesPerView: 4
          }
        }
      });
    }
  }
}
